import { PrinterModel } from "../../shop-settings/utils/printerModelConstants";
import { BillingInfo, Terminal } from "Types";

/** Temporary here - will move into gloabl types */
export type POS = {
    id?: string;
    shopId: string;
    onlineExpress: boolean;
    name: string;
    description: string;
    postponePaymentEnabled: boolean;
    wasteOrderEnabled: boolean;
    cookieId?: string | null;
    expressPOS?: ExpressPOS;
    receiptPrinter: ReceiptPrinter;
    posControlUnitValues?: PosControlUnitValues;
    allowPhonePostponeOrders: boolean;
    puckEnabled: boolean;
    autoRotateMenus: boolean;
    preferTakeAway: boolean;
    preferDigitalReceipts: boolean;
    publicId: string;
    cashChangeInputs?: CashChangeInputs[];
    connectedTerminalIds: string[];
    menuIds: string[];
    sameCardRefund: boolean;
    expressPOSVersion: string | null;
};

export type CashChangeInputs = {
    amount: number;
    dateTime: string;
};

type PosControlUnitValues = {
    posName?: string;
    apiKey?: string;
    productionNumber?: string;
    activationId?: string;
    controlUnitNumber?: string;
};

export type ReceiptPrinter = {
    deviceName: string;
    ip: string;
    usbLegacyPrinter?: boolean;
    printerModal?: PrinterModel;
};

type ExpressPOS = {
    startScreenHeader: string;
    startScreenBread: string;
    startScreenBackgroundImgUrl?: string;
    startScreenBackgroundImgBlurred: boolean;
    startScreenLogoImgUrl: string;
    pairedDeviceCookieId?: string;
    allowTableService: boolean;
    tableServiceEatingOptions: string[];
    allowLimitedPOSFeatures: boolean;
};

type PublicShopInfo = {
    id: string;
    name: string;
    publicId: string;
    imageUrl: string;
};

export type MenusForPOS = {
    id: string;
    name: string;
    disabled: boolean;
};

// used for the awaitConfirm modal which returns value
export type CashMoneyChangeInput = {
    value: CashMoneyChange;
};

type CashMoneyChange = {
    changeAmount: number;
    withdrawnAmount: number;
};

/** POS ADMIN - queries */
export namespace PosQueries {
    export type GetPoses = {
        getAllPosByShopId: POS[];
    };
    export type GetPairedPos = {
        getPairedPos: POS;
    };
    export type GetPublicShopInfo = {
        getPublicShopInfosByIds: PublicShopInfo[];
    };
    export type GetPosShopMenus = {
        getShopMenus: MenusForPOS[];
    };
    export type GetExpressMenus = {
        getExpressMenus: MenusForPOS[];
    };
    export type GetTerminals = {
        getTerminals: Terminal[];
    };
    export type GetShopBillingInfo = {
        getShopBillingInfo: BillingInfo;
    };
}

export enum ExpressPOSVersion {
    V1 = "V1",
    V2 = "V2"
}
