import { isEmpty } from "lodash";

import { InitialDefaultValues, setInitialFormValues, emptyArrayType } from "Utils";
import { CashChangeInputs, ExpressPOSVersion, POS } from "./posTypes";
import { EatingOption } from "Types";

export const getPosInitialValues = (isExpress: boolean, pos?: POS): { initialValues: POS } => {
    const defaultValues: InitialDefaultValues<POS> = {
        postponePaymentEnabled: false,
        allowPhonePostponeOrders: false,
        wasteOrderEnabled: false,
        puckEnabled: false,
        autoRotateMenus: false,
        preferTakeAway: false,
        name: "",
        description: "",
        menuIds: [],
        connectedTerminalIds: [],
        receiptPrinter: {
            ip: "",
            deviceName: "local_printer",
            ...(isExpress && { usbLegacyPrinter: false })
        },
        publicId: "",
        shopId: "",
        onlineExpress: false,
        preferDigitalReceipts: false,
        sameCardRefund: false,
        cashChangeInputs: emptyArrayType<CashChangeInputs>(),
        ...(isExpress && {
            expressPOS: {
                startScreenHeader: "",
                startScreenBread: "",
                startScreenBackgroundImgUrl: "",
                startScreenBackgroundImgBlurred: true,
                startScreenLogoImgUrl: "",
                allowTableService: false,
                tableServiceEatingOptions: [EatingOption.EAT_HERE],
                allowLimitedPOSFeatures: false
            }
        }),
        expressPOSVersion: pos?.expressPOSVersion || ExpressPOSVersion.V1
    };

    const initialValues = setInitialFormValues<POS>(defaultValues, pos);

    if (isEmpty(initialValues.receiptPrinter.deviceName)) {
        return {
            initialValues: {
                ...initialValues,
                receiptPrinter: { ...initialValues.receiptPrinter, deviceName: "local_printer" }
            }
        };
    }

    return {
        initialValues: initialValues
    };
};
